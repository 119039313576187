import React, { useEffect, useState } from "react"
import '../lists.scss';
import Select from 'react-select';
import axios from 'axios';
import { dbURLConcerts, dbURLConcertListsHeading, customStyles200, dbURListsRefs, customStylesSmall } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import { Link, useParams } from 'react-router-dom';
import {DebounceInput} from 'react-debounce-input';
import Highlighter from "react-highlight-words";
import SEO from '../../SEO';
import { SearchLists, SelectProperty } from "../misc/utils";
import ReactMarkdown from "react-markdown";

const ConcertsListsFunction = () => {
    const [concerts, setConcerts] = useState([])
    const [list, setList] = useState([])
    const [references, setReferences] = useState([])
    const [loadingConcerts, setLoadingConcerts] = useState(true)
    const [loadingList, setLoadingList] = useState(true)
    const [errorConcerts, setErrorConcerts] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [errorReferences, setErrorReferences] = useState(null)
    const [optionRange, setOptionRange] = useState('1960s')
    const [optionCity, setOptionCity] = useState(null)
    const [optionYear, setOptionYear] = useState(null)
    const [searchSetlist, setSearchSetlist] = useState('')
    
    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbURLConcertListsHeading + params.slug)
            .then(response => {
                setLoadingList(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchConcerts = () => {
            axios.get(dbURLConcerts + params.slug)
            .then(response => {
                setLoadingConcerts(false)
                setConcerts(response.data)
            }).catch(errorConcerts => {
                setErrorConcerts(errorConcerts);
            });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefs + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        fetchList()
        fetchConcerts()
        fetchReferences()
      }, [params.slug])

    if (errorList) return `Error List: ${errorList.message}`;
    if (errorConcerts) return `Error Songs: ${errorConcerts.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;

    if (loadingList || loadingConcerts) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/lists">
                                        Go to current Lists
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const {name, list_description, picture_link, page, id, range_500, markdown_personnel} = list[0];

    let searchWords, YearOptions, cityOptions, filterArray, ListPic, ListPic2, rangeArray, sortedReferences
    //console.log('Range_500:' + range_500);

    YearOptions = SelectProperty(concerts, 'gig_year')
    cityOptions = SelectProperty(concerts, 'city')

    const selectedOptionYear = (selectedYear) => {
        setOptionYear(selectedYear);
    }
    const selectedOptionCity = (selectedCity) => {
        setOptionCity(selectedCity);
    }

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    //RangeSelection if large lists
    if (optionRange === '1960s') {
        rangeArray = concerts.filter(e => e.gig_year < 1970);  
        }
    if (optionRange === '1970s') {
        rangeArray = concerts.filter(e => e.gig_year > 1969 && e.gig_year < 1980);  
        } 
    if (optionRange === '1980s') {
        rangeArray = concerts.filter(e => e.gig_year > 1979 && e.gig_year < 1990);  
        } 
    if (optionRange === '1990s') {
        rangeArray = concerts.filter(e => e.gig_year > 1989 && e.gig_year < 2000);  
        } 
    if (optionRange === '2000s') {
        rangeArray = concerts.filter(e => e.gig_year > 1999 && e.gig_year < 2010);  
        } 
    if (optionRange === '2010s') {
        rangeArray = concerts.filter(e => e.gig_year > 2009 && e.gig_year < 2020);  
        } 
    if (optionRange === 'All') {
        rangeArray = concerts;  
        }
    
    //IF NOT range List
    if (!range_500) {
        rangeArray = concerts; 
    }

    //Search
    if (searchSetlist) {
        searchWords = searchSetlist.split(/\s/).filter(word => word)
        filterArray = SearchLists(concerts, 'search_setlist', searchSetlist.toLowerCase());
    }

    const handleSearchChangeSetlist = (e) => {
        setSearchSetlist(e.target.value);
        };

    const clearSearchFieldSetlist = (e) => {
        setSearchSetlist('')
        }

    //FILTERS
    if (optionYear) {
        filterArray = concerts.filter(e => e.gig_year === optionYear.value);
        }  
    if (optionCity) {
        filterArray = concerts.filter(e => e.city === optionCity.value);
        } 
    if (!searchSetlist && !optionCity && !optionYear) {
        filterArray = rangeArray;
    }

    const listHeading = list.map(value => {

        ListPic = value.picture
        ListPic2 = value.list_picture

        return (
            <React.Fragment key={value.id}>
                <div>
                    <h2>{value.name}</h2>

                    <div className="row mb-2">
                        
                        {/* kolonne 1 */}
                        <div className="col-sm-3 mb-3">
                            <div className='mb-2'>
                            {
                                (() => {
                                if (ListPic) {
                                return  <div className='my-2'><img src={ListPic} alt="alt" className="img-fluid" /> </div>
                                }
                                return <div className='my-2'><img src={ListPic2} alt="alt" className="img-fluid" /> </div>
                                })()
                            }
                            </div>

                            <div className="btn btn-secondary btn-xs mt-3 mb-2">A total of: <b>{concerts.length}</b> concerts</div><br />

                            

                            {
                                (() => {
                                    if (value.fav_concerts) {
                                        return <div><b>My top 3</b><br />
                                                <div className="Date pl-1 Description">{value.fav_concerts}</div>
                                            </div>
                                    }
                                    return 
                                })()
                            }
                            
                        </div>
                        
                        {/* kolonne 2 */}
                        <div className="col-sm-5 Description">
                        <h5>{value.tour_title}</h5>
                            {
                                (() => {
                                    if (value.concert_tours_comment) {
                                        return <div className="card bg-light pt-2 px-2 m-2">
                                                    <p className="quote2">{value.concert_tours_comment}</p> 
                                                    
                                                    {
                                                        (() => {
                                                            if (value.concert_tours_comment_source_details) {
                                                                return <div><i>- {value.concert_tours_comment_source} ({value.concert_tours_comment_source_details})</i></div>
                                                            }
                                                            return <div><i>- {value.concert_tours_comment_source}</i></div>
                
                                                        })()
                
                                                    }
                                                
                                                </div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                if (value.tour_description) {
                                return  <div>
                                        <div className='my-3 p-2 rounded'>
                                            <ReadMoreAndLess
                                                    charLimit={500}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.tour_description}
                                            </ReadMoreAndLess>
                                        </div>
                                    </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                    if (value.comment1) {
                                        return <div className="DescriptionBig  card bg-light pt-2 px-2 mx-2 mb-3">
                                                    <p className="quote">{value.comment1}</p> 
                                                    <div><i>- {value.source1}</i></div>
                                                </div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                if (value.list_description) {
                                return  <div>
                                    <div className="DescriptionBig rounded mx-2 mt-2 mb-3 p-2">
                                            <ReadMoreAndLess
                                                    charLimit={400}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.list_description}
                                            </ReadMoreAndLess>
                                        </div>
                                    </div>
                                }
                                return 
                                })()
                            }
                            
                            

                            <div>

                                {
                                    (() => {
                                    if (value.url) {
                                    return  <div>
                                            <b>Source</b>
                                            <div className="Description">
                                                <ul>
                                                    <li>
                                                        <b><a href={value.url}>
                                                            {value.name} ({value.source})
                                                        </a></b>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                    </div>
                                    }
                                    return 
                                    })()
                                }

                                <b>More stuff:</b>
                                <div className="Description">
                                    <ul>
                                        {
                                            (() => {
                                            if (value.link1 && value.link2 && value.link3 && value.link4 && value.link5) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url4}>
                                                            {value.link4}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url5}>
                                                            {value.link5}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url4}>
                                                            {value.link4}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2 && value.link3) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                            if (value.link1) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            return 
                                            })()
                                        }

                                        <div className="mt-3">
                                        <li><b><a href='/lists'>
                                            More Lists @ MusicThisDay.com
                                            </a></b>
                                        </li>
                                        </div>
                                        
                                    </ul>
                                </div>
                            </div>
                        
                        </div>

                        {/* kolonne 3 */}
                        <div className="col-sm-4 mb-1">

                            {(id !== 105 && id !== 211 && id !== 346 && id !== 414) &&
                                <div className="mb-2">
                                    <div><b>Start date </b><br />
                                        <div className="Date pl-1" > {value.start_date}</div>
                                    </div>
                                    <div><b>End date </b><br />
                                        <div className="Date pl-1">{value.end_date}</div>
                                    </div>
                                    <div><b>Legs</b><br />
                                        <div className="Date pl-1">{value.legs}</div>
                                    </div>
                                    <div><b>Number of shows</b><br />
                                        <div className="Date pl-1">{value.no_shows}</div>
                                    </div>
                                </div>
                            }
                            
                            <div className="mt-1">
                                <h3>Filters / Search</h3>
                                {(!searchSetlist && !value.no_city)  &&
                                    <div className="row pb-2 mt-2">  
                                            <div className="col-9">
                                                <Select
                                                    className="select"
                                                    value={optionCity}
                                                    onChange={selectedOptionCity}
                                                    options={cityOptions}
                                                    placeholder='Select City'
                                                    styles={customStyles200}
                                                />
                                            </div>

                                            <div className="col-3 pl-0">
                                            <button  className="btn btn-primary btn-sm"  
                                            onClick={() => setOptionCity(null)} >Reset</button>
                                            </div>
                                    </div>
                                }
                                {(!searchSetlist && value.year_filter)  &&
                                <div>
                                    <div className="row my-2">  
                                        <div className="col-6">
                                            <Select
                                                className="select"
                                                value={optionYear}
                                                onChange={selectedOptionYear}
                                                options={YearOptions}
                                                placeholder='Select a Year'
                                                styles={customStylesSmall}
                                            />
                                        </div>
                                        <div className="col-6 pl-2">
                                            <button  className="btn btn-primary btn-sm"  onClick={() => setOptionYear(null)} >Reset</button>
                                        </div>
                                    </div>
                       
                                </div>
                                }

                                {(!optionCity && !optionYear)  &&
                                <div>
                                    <div className="row">  
                                        <div className="col-6">
                                            <div className="Search"> 
                                                
                                                    <DebounceInput
                                                        //type="number"
                                                        minLength={3}
                                                        debounceTimeout={1000}
                                                        value={searchSetlist}
                                                        onChange={handleSearchChangeSetlist}
                                                        placeholder="Search all setlist" 
                                                        className="searchSmall"
                                                    />
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="col-6 pl-0">
                                            <button type="button" className="btn btn-primary btn-sm"  
                                            onClick={() => clearSearchFieldSetlist()}>Reset</button>
                                        </div>
                                    </div>
                                    
                                   


                                </div>
                                }
                                {searchSetlist &&
                                    <div>
                                        <div className='mt-3'>
                                            <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                        </div> 
                                    </div>
                                }
                            </div>
                            
                            <div className="btn btn-secondary btn-xs mt-3 mb-2">{filterArray.length} selected concerts</div>

                        </div>
                        
                    </div>

                    {range_500 && 
                    <div className="row">
                    
                        <div className='col-sm-10 mb-2' align="Left">
                            <div className='mb-2'><strong>Select decade to filter: </strong></div>
                            {optionRange === '1960s' && 
                            <button className="btn btn-info btn-xs active focus m-1" onClick={() => setOptionRange('1960s')}>1960s</button>
                            }
                            {optionRange !== '1960s' && 
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('1960s')}>1960s</button>
                            }
                            <button type="button" className="btn btn-info btn-sm mr-1" onClick={() => setOptionRange('1970s')}>1970s</button>
                            
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('1980s')}>1980s</button>
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('1990s')}>1990s</button>
                            <button type="button" className="btn btn-info btn-sm m-1" onClick={() => setOptionRange('2000s')}>2000s</button>
                            
                            <button type="button" className="btn btn-sm btn-info mr-2 mt-lg-0 m-1" onClick={() => setOptionRange('2010s')}>2010s</button>
                            
                        </div>
                    

                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    }



                    {!range_500 && 
                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    }

                    
                    
                </div>
            </React.Fragment>
            )

     }
    )

    const concertItems = filterArray.map(value => {

        let charlimitSetlist

        if (value.charlimit_setlist) {
            charlimitSetlist = value.charlimit_setlist;
        }
        if (!value.charlimit_setlist) {
            charlimitSetlist = 800;
        }

        return (

            <React.Fragment key={value.id}>
                
                <div className="row p-1 rounded concertsLists22 mx-1">
                    <div className="col-sm-12"> 
                        <div className="badge bg-secondary col-md-4 float-md-end ms-md-2 my-1">{value.tour}</div>
                        &nbsp;<b>{value.venue} - {value.city}, {value.country} - {value.gig_date}&nbsp;&nbsp;&nbsp;</b>               
                        
                    </div>
                </div>

                <div className="row mb-5 pt-3 pb-2">
                    {/* Column 1 */}
                    <div className="col-sm-3">
                            {value.picture_link &&
                                <div><img src={value.picture_link} 
                                alt="alt" className="rounded img-fluid" /> </div>
                            }
                            
                            <div className="Header4 p-2 mt-3 rounded">
                                <b>Line-up:</b><br />
                                    
                                        {markdown_personnel &&
                                            <div className="factTextMarkdown p-1 rounded">
                                                <ReactMarkdown>
                                                    {value.musicians}                   
                                                </ReactMarkdown>
                                            </div>
                                        }

                                        {!markdown_personnel &&
                                            <div className="factText p-1 rounded">
                                                {value.musicians}   
                                            </div>               
                                        }
                                    
                                
                            </div>

                            {
                                (() => {
                                if (value.spotify) {
                                    return  <div><br />
                                        <p align="center"><b>Spotify link</b></p>
                                        <p className="aligncenter"><a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="Spotify" align="center" src="/images/spotify.png" width="60" height="60" /></a></p>
                                    </div>
                                    
                                }
                                return 
                                })()
                            }

                        </div>
                    
                    {/* Column 2 */}
                    <div className="col-sm-5 py-1 border-right Description">
                      
                                            
                      <div className="bg-light ml-2 mr-2 py-1 px-2 rounded">
                          <b>Setlist:</b><br />
                          {
                              (() => {
                                  if (!searchSetlist) {
                                      return  <div>
                                                  <ReadMoreAndLess
                                                      charLimit={charlimitSetlist}
                                                      readMoreText={" more ▼"}
                                                      readLessText={" less ▲"}
                                                      readMoreClassName="read-more-less--more"
                                                      readLessClassName="read-more-less--less"
                                                          >
                                                          {value.setlist}
                                                  </ReadMoreAndLess><br />
                                              </div>
                                      }
                                      if (searchSetlist) {
                                      return  <div>
                                                  <Highlighter
                                                          highlightClassName="YourHighlightClass"
                                                          searchWords={searchWords}
                                                          autoEscape={true}
                                                          textToHighlight={value.setlist}
                                                      /><br />
                                              </div>  
                                  }
                                  return
                                  }
                              )()
                          }
                          

                      </div>
                              {
                                  (() => {
                                  if (value.description) {
                                  return  <div className="bg-light p-2 rounded mt-3">
                                              <ReadMoreAndLess
                                                      charLimit={400}
                                                      readMoreText={" Read more ▼"}
                                                      readLessText={" Read less ▲"}
                                                      readMoreClassName="read-more-less--more"
                                                      readLessClassName="read-more-less--less"
                                                  >
                                                  {value.description}
                                              </ReadMoreAndLess>
                                              
                                          </div>
                                  
                                  }
                                  if (!value.description && value.comment) {
                                      return  <div className="card rounded bg-light pt-3 px-2 mt-4">
                                                  <p className="quote3">{value.comment}</p> 
                                                  
                                                  {
                                                      (() => {
                                                          if (value.source_txt_details) {
                                                              return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                          }
                                                          return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                      })()

                                                  }

                                              </div>
                                  }


                                  return 
                                  })()
                              }
                          
                          
                      </div>
                    
                    {/* Column 3 */}
                    <div className="col-sm-4">

                        {
                            (() => {
                                if (value.youtube) {
                                    return <div>
                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                    <ReactPlayer
                                                        url={ value.youtube||value.vimeo }
                                                        light='true'
                                                        //light
                                                        controls
                                                        volume={0.9}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                            </div>
                                        </div>
                                    }
                                    return <h5>[No videos found on youtube]</h5>
                            })()
                        }
                        {
                            (() => {
                                if (value.youtube_2) {
                                    return <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_2}</b></div>
                                                    <ReactPlayer
                                                        url={ value.youtube_2 }
                                                        light='true'
                                                        //light
                                                        controls
                                                        volume={0.9}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                            </div>
                                        </div>
                                    }
                            })()
                        }
                        {
                            (() => {
                                if (value.youtube_3) {
                                    return <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_3}</b></div>
                                                    <ReactPlayer
                                                        url={ value.youtube_3 }
                                                        light='true'
                                                        //light
                                                        controls
                                                        volume={0.9}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                            </div>
                                        </div>
                                    }
                            })()
                        }
                        {
                            (() => {
                                if (value.youtube_4) {
                                    return <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_4}</b></div>
                                                    <ReactPlayer
                                                        url={ value.youtube_4 }
                                                        light='true'
                                                        //light
                                                        controls
                                                        volume={0.9}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                            </div>
                                        </div>
                                    }
                            })()
                        }
                        {value.youtube_5 &&
                            <div>
                                <div className="border my-2">
                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_5}</b></div>
                                        <ReactPlayer
                                            url={ value.youtube_5 }
                                            light='true'
                                            //light
                                            controls
                                            volume={0.9}
                                            width="100%"
                                            height="100%"
                                        />
                                </div>
                            </div>
                        }

                        <div className="row pt-3">
                            <div className="col-12">
                                {
                                    (() => {
                                    if (value.comment && value.comment_2 && value.description) {
                                        return <div>
                                                <div className="card bg-light py-2 px-2">
                                                    <p className="quote3">{value.comment}</p> 
                                                    
                                                    {
                                                        (() => {
                                                            if (value.source_txt_details) {
                                                                return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                            }
                                                            return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                        })()

                                                    }

                                                </div><br />
                                        
                                                <div className="card bg-light py-2 px-2">
                                                    <p className="quote3">{value.comment_2}</p> 
                                                    
                                                    {
                                                        (() => {
                                                            if (value.source_txt_details_2) {
                                                                return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                            }
                                                            return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                        })()

                                                    }

                                                </div>
                                            </div>
                                                            
                                    }


                                    if (value.comment && value.description) {
                                        return <div className="card bg-light py-2 px-2">
                                                    <p className="quote3">{value.comment}</p> 
                                                    
                                                    {
                                                        (() => {
                                                            if (value.source_txt_details) {
                                                                return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                            }
                                                            return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                        })()

                                                    }

                                                </div>
                                                            
                                    }
                                    return 
                                    })()
                                }
                            </div>
                        </div> 

                    </div>

                </div>

            </React.Fragment>
        )
    })

    //REFERENCES
    const listReferences = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>

                {
                    (() => {
                    if (value.book_title) {
                    return  <div className="row px-1 my-1">
                                <div className="col">
                                    <div>{value.author_txt} ({value.published_year}).
                                    <a href={value.more_info}> {value.book_title}</a>. {value.publisher}. (book) </div>
                                </div>
                            </div>
                    } if (value.person && !value.date_txt) {
                    return <div className="row px-1 my-1">
                            <div className="col">
                                <a href={value.url}>
                                    {value.title}
                                </a> ({value.person})
                            </div>
                        </div>
                    } if (!value.person && value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> ({value.date_txt})
                                    </div>
                                </div>
                    }
                    if (!value.person && !value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> 
                                    </div>
                                </div>
                    }
                    return <div className="row px-1 my-1">
                                <div className="col">
                                    {value.person} ({value.date_txt}). <a href={value.url}>
                                        {value.title}
                                    </a>
                                </div>
                            </div>
                    })()
                }

                
            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {list.length > 0 && (
                <div className="container">
                
                    <SEO
                    title={name}
                    description={list_description}
                    name={name}
                    picture_link={picture_link}
                    url={'https://www.musicthisday.com/'+page}
                    canonical={'https://www.musicthisday.com/'+page}
                    type='article' />

                    <div className="row">
                        <div className="col">
                            {listHeading}
                        </div>
                    </div>

                   
                     <div className="row">
                        <div className="col">

                             {
                                (() => {
                                if (concerts.length > 0) {
                                return <div> { concertItems } </div>
                                }
                                return <div>
                                        <h3>No concerts found</h3>
                                    </div>
                                })()
                            } 
                           
                        </div>
                    </div> 

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>

                    {
                        (() => {
                        if (references.length > 0) {
                        return  <div>
                                        <div className="row mt-4">
                                            <div className="col">
                                                    <h4>References & similar lists</h4>
                                            </div>
                                        </div>
                                        <div className="row px-1">
                                            <div className="col">
                                                <b>
                                                <a href='https://en.wikipedia.org'>
                                                    Wikipedia
                                                </a>
                                                </b>
                                            </div>
                                        </div>
                                    
                                        <div className="row mt-2">
                                            <div className="col">
                                                    { listReferences }
                                            </div>
                                        </div>
                                </div>
                        }
                        return 
                        })()
                    }

                </div>
            )}
        </main>
    )



}
export default ConcertsListsFunction